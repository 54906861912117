var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"08b472400e11449d1a34b6724be3c785c71ea3db"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { SENTRY_DSN } from '../../config/public';
import { getTraceURL } from '../../tracing';

Sentry.init({
  dsn: SENTRY_DSN,

  instrumenter: 'otel',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    new Sentry.Replay({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
    new Sentry.BrowserTracing(),
  ],

  ignoreErrors: [
    'staticGenerationBailout', // this error occurs when next is building and finds a dynamic page
    'The specific message is omitted in production builds to avoid leaking sensitive details', // we can't extract much information from it
  ],

  beforeSend(event, _hint) {
    const traceId = document.head
      .querySelector('meta[name="traceid"]')
      ?.getAttribute('content');

    const startTimeMeta = document.head
      .querySelector('meta[name="start_time"]')
      ?.getAttribute('content');
    const startTime = parseInt(startTimeMeta || '0') || Date.now();

    if (traceId) {
      event.tags = event.tags ?? {};
      event.tags.trace_url = getTraceURL(traceId, startTime);
    }
    return event;
  },
});
