import type { SpanContext } from '@opentelemetry/api';
import { isSpanContextValid, trace } from '@opentelemetry/api';
import type { tracing } from '@opentelemetry/sdk-node';

import { SERVICE_NAME } from '../config/public';

export const getSpanFromContext = (): tracing.Span | undefined => {
  return trace.getActiveSpan() as tracing.Span;
};

export const getSpanContextFromContext = (): SpanContext | undefined => {
  const span = trace.getActiveSpan();
  if (!span) return;

  const spanContext = span.spanContext();
  if (!isSpanContextValid(spanContext)) return;

  return spanContext;
};

export const getTraceId = (): string | undefined => {
  const spanContext = getSpanContextFromContext();
  return spanContext?.traceId;
};

export const getSpanId = (): string | undefined => {
  const spanContext = getSpanContextFromContext();
  return spanContext?.spanId;
};

export const getTraceparentHeader = () => {
  const spanContext = getSpanContextFromContext();
  if (!spanContext) return '';

  return `00-${spanContext.traceId}-${spanContext.spanId}-01`; // 00=not sampled; 01=sampled -- we always use sampled
};

const oneMinuteInMs = 60_000;
const offset = 5 * oneMinuteInMs;
export const getTraceURL = (traceId: string, startTimeMs: number) => {
  // Start time here must be closer to when the trace started, not the current span.
  // It's important to have this offset, because honeycomb only looks 10 minutes ahead of `trace_start_ts`.
  // This way we don't have the risk of adding a start time after the time that the trace started.
  const startTimeMinusOffset = startTimeMs - offset;
  const startTimeInSecs = Math.floor(startTimeMinusOffset / 1000);

  return `https://ui.honeycomb.io/next8r/environments/prod/datasets/${SERVICE_NAME}/trace?trace_id=${traceId}&trace_start_ts=${startTimeInSecs}`;
};
